<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="adddesc">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="infolist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <el-table-column prop="title" :label="$t('jj.biaoti')"></el-table-column>
      <el-table-column
        prop="content"
        :label="$t('jj.neirong')"
      ></el-table-column>
      <el-table-column prop="image" :label="$t('jj.tupian')">
        <template slot-scope="{ row }">
          <img :src="row.image" alt="" style="max-width:60px;max-height:60px" />
        </template>
      </el-table-column>

      <el-table-column
        prop="type_name"
        :label="$t('jj.leixing')"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加简介弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="infoform"
        label-width="140px"
        ref="infoform"
        :rules="formrules"
      >
        <el-form-item :label="$t('jj.biaoti')" prop="title">
          <el-input v-model="infoform.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.neirong')" prop="content">
          <el-input v-model="infoform.content"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.tupian')" prop="image">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/fund/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload"
          >
            <img v-if="infoform.image" :src="infoform.image" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('jj.leixing')" prop="types">
          <el-select v-model="infoform.type">
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑简介弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="infoform"
        label-width="140px"
        ref="infoform"
        :rules="formrules"
      >
        <el-form-item :label="$t('jj.biaoti')" prop="title">
          <el-input v-model="infoform.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.neirong')" prop="content">
          <el-input v-model="infoform.content"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.tupian')" prop="image">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/user/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload"
          >
            <img v-if="infoform.image" :src="infoform.image" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item :label="$t('jj.leixing')" prop="type">
          <el-select v-model="infoform.type">
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      infolist: [],
      total: 0,
      loading: true,
      // 所有类型
      types: [],
      // 添加数据对象
      infoform: {
        title: '',
        content: '',
        image: '',
        type: ''
      },
      dialogvisible: false,
      formrules: {
        title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        content: [{ required: true, message: '请填写内容', trigger: 'blur' }],
        image: [{ required: true, message: '请上传图片', trigger: 'blur' }]
      },
      editdialogvisible: false,
      // 设置图片上传的token
      headerobj: {
        Authorization: window.sessionStorage.getItem('token')
      }
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/fund/description', {
        params: this.queryinfo
      })
      this.infolist = data.data.list
      this.loading = false
    },
    // 添加弹窗
    async adddesc() {
      this.dialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/fund/adddescription')
      if (data) {
        if (data.code === 200) {
          this.types = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交添加
    submit() {
      this.$refs.infoform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/fund/adddescription',
          this.infoform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
            this.handleClose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 关闭添加弹窗
    handleClose() {
      this.$refs.infoform.resetFields()
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },

    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get(
        '/admin/fund/editdescription/id/' + id
      )
      this.infoform.title = data.data.info.title
      this.infoform.content = data.data.info.content
      this.infoform.image = data.data.info.image
      this.infoform.type = data.data.info.type_id
      this.types = data.data.types
      this.infoform.id = data.data.info.id
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.infoform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/fund/editdescription',
          this.infoform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
            this.editclose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.$refs.infoform.resetFields()
      this.editdialogvisible = false
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('queding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/fund/deldescription', {
          id
        })

        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 图片上传成功操作
    upload(res) {
      this.infoform.image = res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>
